import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { AnimatePresence, motion } from "framer-motion"
import SanityImage from "gatsby-plugin-sanity-image-alt"
import loadable from "@loadable/component"
import { useWindowSize } from "../../helpers/useWindowSize"
import { useSanityImageShape } from "../../helpers/useSanityImageShape"

const Button = loadable(() => import("./Button"))
const Pagination = loadable(() => import("./Pagination"))

const Slides = ({ images }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < 640
  const direction = 0
  const swipeConfidenceThreshold = 5000
  const imageWidth = 1080
  const imageHeight = 600

  const total = images?.length

  const paginate = newDirection => {
    if (slideIndex + newDirection === total) return setSlideIndex(total - 1)
    if (slideIndex + newDirection < 0) return setSlideIndex(0)

    return setSlideIndex(slideIndex + newDirection)
  }

  const swipePower = (offset, velocity) => Math.abs(offset) * velocity

  const handleArrowKeys = evt => {
    if (evt.keyCode === 39) {
      evt.preventDefault()
      paginate(1)
    }
    if (evt.keyCode === 37) {
      evt.preventDefault()
      paginate(-1)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleArrowKeys)

    return () => document.removeEventListener("keydown", handleArrowKeys)
  })

  const imageShaped = useSanityImageShape(images[slideIndex])

  return (
    <>
      {!isMobile && (
        <Button paginate={paginate} prev disabled={slideIndex === 0} />
      )}
      <AnimatePresence custom={direction}>
        <motion.div
          layout
          className="absolute inset-0"
          key={slideIndex}
          custom={direction}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            opacity: { duration: 0.35 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x)

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1)
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1)
            }
          }}
        >
          {images && (
            <SanityImage
              {...imageShaped}
              className="object-contain mx-auto h-full bg-center pointer-events-none"
              alt={images[slideIndex].alt}
              width={images[slideIndex].asset.width}
              height={images[slideIndex].asset.height}
            />
          )}
        </motion.div>
        <Pagination
          total={total}
          slideIndex={slideIndex}
          isMobile={isMobile}
          setSlideIndex={setSlideIndex}
        />
      </AnimatePresence>
      {!isMobile && (
        <Button paginate={paginate} next disabled={slideIndex === total - 1} />
      )}
    </>
  )
}

export default Slides

Slides.propTypes = {
  images: PropTypes.array,
}
